.navbar {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: center;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center; 
    font-weight: lighter;
    text-align: center;
}


.fat-logo-text {
  color: #fff;
  /* justify-self: center; */
  margin-top: 25px;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: .9rem;
  display: flex;
  /* align-items: center; */
  font-weight: lighter;
  text-align: center;
}
  


  .fat-logo{
    background: url('/public/images/fat-logo.svg') center/cover;
    border-radius: 0%;
    width: 100px;
    height: 100px;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars, .fa-times {
    color: #5c5a5a;
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
    font-size: 40px;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
    justify-content: right;
    align-items: right;
  }
  
  @media screen and (max-width: 960px) {
   .menu-icon {
    display: flex;
   }

    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
    
    .navbar-logo{
      font-size: 1rem;
    }
}