.about-container {
    background: url('/public/images/fat-img-4.jpg') center center/cover 
    no-repeat; 
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
    justify-content: center;
    }

    .about-container > h1 {
        color: #fff;
        font-size: 75px;
        margin-top: -100px;
        justify-content: center;
    }
    
    .about-container > p {
        color: #fff;
        font-size: 32px;
        margin-top: 8px;
    
    }
    
    .home-btn {
        margin-top: 32px;
    }
    
    home-btns .btn {
        margin: 6px; 
    }
    
    @media screen and (max-width: 960px){
        .about-container > h1 {
            font-size: 50px;
            margin-top: -150px;
        }
    }
    
    @media screen and (max-width: 768px){
        .about-container > h1 {
            font-size: 20px;
            margin-top: -100px;
        }
        .about-container > p {
            font-size: 30px;
        }
    
        .btn-mobile {
            display: block;
            text-decoration: none;
        }
    
        .btn {
            width: 100%;
        }
    }