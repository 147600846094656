.contact-info-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
}

h1, h2, h3 {
    text-align: center;
  }


  @media screen and (max-width: 960px){
    .contact-info-container > h2 {
        font-size: 30px;
       
    }
}

@media screen and (max-width: 768px){
    .contact-info-container > h2 {
        font-size: 1rem;
      
    }
}