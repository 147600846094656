
.footer-container {
    background-color: #242424;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-logo-section {
    margin-top: 24px;
    justify-content: center;
    text-align: center;
    align-items: center;
   margin-left: 100px;
    max-width: 1000px;

    justify-content: center;
  }

  .fa-moon { 
    margin: 5px;
  }
  
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
  }

  .footer-insta-heading {
    color: #fff;
    font-size: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  /* .footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  } */
  
  .footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
  }
  
  .footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
  }
  
  .footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
  }
  
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;

  }
  
  .footer-link-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .footer-link-items {
    /* display: flex; */
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
  }
  
  .footer-link-items h2 {
    margin-bottom: 16px;
  }
  
  .footer-link-items > h2 {
    color: #fff;
  }
  
  .footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    margin-right: 24px;
  }
  
  .footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
  }
  
  .footer-email-form h2 {
    margin-bottom: 2rem;
  }
  
  .footer-input::placeholder {
    color: #b1b1b1;
  }
  
  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }
  
  .social-media {
    max-width: 1000px;
    width: 100%;
  }
  
  .social-media-wrap {
    /* display: flex; */
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
  }
  
  .social-icons {
    /* display: flex; */
    justify-content: space-evenly;
    align-items: center;
    width: 120px;
  }
  
  .social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  .footer-logo {
    background: url('/public/images/fat-logo.svg') center/cover;
        /* border-radius: 2%; */
        width: 200px;
        height: 200px;
  }


  .footer-logo-text {
    color: #fff;
    /* justify-self: center; */
    margin-top: 60px;
    margin-left: 15px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    /* align-items: center; */
    font-weight: lighter;
    text-align: center;
}

.footer-instagram-post{
 

    margin:  20px;

}


.footer-instagram-section{

    display: flex;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;

}

.footer-link:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
  }

  .website-rights {
    color: #fff;
    margin-top: 20px;
    margin-bottom: 16px;
    margin-right: 80px;
    display: flex;
  }
  
  @media only screen and (max-width: 820px) {
    .footer-links{
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }
  
  @media only screen and (max-width: 768px) {
  }
  