
.email-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    height: 50%;
    margin: 0 auto;
  }
  
  .email-wrapper {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 10px 0 45px;
  }

  .email-textarea{
    width: 500px;
    height: 200px;
  }


.email-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid black;
  }

  .send-email-btn {
    margin-bottom: 16px;
    padding: 8px 20px;
  }

  .textarea{
    width: 100%;
    box-sizing: border-box;
  }


 @media only screen and (max-width: 820px) {
   
    .email-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    
  }
  
  @media only screen and (max-width: 768px) {
  }