.not-found-wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 30vh;
    justify-content: center;
    font-size: 1rem;
  }

  .not-found-link:hover {
    border-bottom: 4px solid black;
    transition: all 0.2s ease-out;
  }
