video {
    object-fit: contain;
    width: 100px;
    height: 100%;
    position: flex;
    z-index: -1;
}


.services-container {
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
        object-fit: contain;
        justify-content: center;
}

home-btns .btn-containter {
  margin: 6px; 
}

@media screen and (max-width: 960px){
    .services-container > h1 {
        font-size: 50px;
        margin-top: -150px;
    }
}

@media screen and (max-width: 768px){
    .services-container > h1 {
        font-size: 20px;
        margin-top: -100px;
    }
    .services-container > p {
        font-size: 30px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}

table {
    border-collapse: collapse;
    width: 100%;
    color: #242424;
    font-family: Arial, sans-serif;
    font-size: 14px;
    text-align: left;
    /* border-radius: 10px; */
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
   
  } 

  table th {
    background-color: #242424;
    color: #fff;
    font-weight: bold;
    padding: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #ccc;
  }

  table tr:nth-child(even) td {
    background-color: #f2f2f2;
  }
  
  table tr:hover td {
    background-color: #242424;
    color: #fff;
  }

  table td {
    background-color: #fff;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    font-weight: bold;
    font-size: medium;
  }

  @media screen and (max-width: 600px) {  
    .responsive thead {
      visibility: hidden;
      height: 0;
      position: absolute;
    }
    
    .responsive tr {
      display: block;
      margin-bottom: .625em;
    }
    
    .responsive td {
      border: 1px solid;
      border-bottom: none;
      display: block;
      font-size: .8em;
      text-align: left;
    }
    
    .responsive td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    
    .responsive td:last-child {
      border-bottom: 1px solid;
    }

  }
  