.about-info-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
}

h1, h2, h3 {
    text-align: center;
  }


  .about-info-detail {
    margin: 24px;
  }


  .about-info-p {
    margin: 10px;
    text-align: left;
    font-size: medium;
  }